<template>
  <el-dialog
    :title="'问题标题：'+title"
    :visible.sync="dialogVisible"
    width="720px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-button
      type="primary"
      size="small"
      @click="form.bindList.push( {
        matchValues: [],
        childQuestionCode: '',
      })"
    >
      添加子题
    </el-button>
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :inline="true"
      class="question-link"
      :model="form"
      label-width="80px"
    >
      <div
        v-for="(item,index) in form.bindList"
        :key="index"
        style="position: relative"
        class="flex-start"
      >
        <el-form-item
          label="子题"
          :prop="'bindList.' + index + '.childQuestionCode'"
          :rules="{required: true, message: '子题不能为空', trigger: 'change'}"
        >
          <el-select
            v-model="item.childQuestionCode"
            class="mr10"
            placeholder="请选择子题"
          >
            <el-option
              v-for="(c,k) in questionOptions"
              :key="k"
              :label="c.title"
              :value="c.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="对应答案"
          :prop="'bindList.' + index + '.matchValues'"
          :rules="{required: true, message: '对应答案不能为空', trigger: 'change'}"
        >
          <el-select
            v-model="item.matchValues"
            class="mr10"
            multiple
            placeholder="请选择题目"
          >
            <el-option
              v-for="(c,k) in answerOptions"
              :key="k"
              :label="c"
              :value="c"
            />
          </el-select>
        </el-form-item>
        <i
          class="el-icon-remove-outline cursor-pointer"
          @click="form.bindList.splice(index,1)"
        />
      </div>

    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import {
  SaveOptionBindChildQuestion, GetQuestionaireChild, QuestionSelect,
} from '@/api/assess/questionTemplate'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      title: '',
      childQuestionCodeOptions: [],
      answerOptions: [],
      questionOptions: [],
      categoryCode: '',
      questionCode: '',
      form: {
        bindList: [

        ],
        code: '',
      },
    }
  },
  methods: {
    getDetail() {
      GetQuestionaireChild(this.form.code).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const bindList = resData.data
          this.form.bindList = []
          if (bindList && bindList.length > 0) {
            this.form.bindList = bindList
          }
        }
      })
    },
    getQuestionSelectOption() {
      QuestionSelect(this.$route.query.c, this.categoryCode, this.questionCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.questionOptions = resData.data
        }
      })
    },
    dialogOpen() {
      this.$nextTick(() => {
        this.getQuestionSelectOption()
        this.getDetail()
      })
    },
    dialogClose() {
      this.form = {
        bindList: [
          {
            childQuestionCodeList: [],
            optionCode: '',
          },
        ],
        code: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          SaveOptionBindChildQuestion(this.form.code, this.form.bindList).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
          return true
        }
        return false
      })
    },
  },
}
</script>
<style>
.question-link .el-input{
  width: 250px;
}
.question-link .el-form-item{
  margin-right: 128px !important;
  position: relative;
}
.question-link .el-form-item__content {
  width: 120px !important;
}
.question-link .el-icon-circle-plus-outline, .question-link .el-icon-remove-outline{
  position: absolute;
  right: 0;
  font-size: 20px;
  top: 10px;
}
</style>
