<template>
  <el-drawer
    :title="title"
    :size="800"
    :visible.sync="drawerVisible"
    @open="drawerOpen"
  >
    <div
      v-if="drawerVisible"
      style="padding: 0 20px"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="说明">
          {{ form.explanation }}
        </el-tab-pane>
        <el-tab-pane label="注意事项">
          {{ form.precautions }}
        </el-tab-pane>
        <el-tab-pane label="相关术语">
          {{ form.relatedTerm }}
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import { GetAffiliateInfo } from '@/api/assess/questionTemplate'

export default {
  data() {
    return {
      drawerVisible: false,
      title: '',
      form: {
        explanation: '',
        precautions: '',
        questionId: '',
        relatedKnowledge: '',
        relatedTerm: '',
        legalBasis: '',
        bestPracticeCodeList: [],
        lawCodeList: [],
      },
    }
  },
  methods: {
    drawerOpen() {
      this.getDetail()
    },
    getDetail() {
      GetAffiliateInfo(this.form.questionId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
