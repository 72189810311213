<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="720px"
    title="添加题目"
    :append-to-body="true"
    :modal-append-to-body="false"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="100px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item
            label="题目类型"
            prop="categoryCode"
            :rules="[ { required: true, message: '题目类型不能为空', trigger: 'change' }]"
          >
            <SelectTree
              v-model="form.categoryCode"
              :props="treeSelectProps"
              :options="categoryCodeOptions"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="编号"
            prop="code"
            :rules="[ { required: true, message: '编号不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.code"
              :disabled="saveType === 2"
              placeholder="请输入编号"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="题目标题"
            prop="title"
            :rules="[ { required: true, message: '题目标题不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.title"
              placeholder="请输入题目标题"
            />
          </el-form-item>
        </b-col>

        <b-col md="6">
          <el-form-item label="排序号">
            <el-input
              v-model.number="form.sortNo"
              type="number"
              placeholder="请输入排序号"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="是包含附件"
            prop="haveAnnex"
            :rules="[ { required: true, message: '是包含附件不能为空', trigger: 'change' }]"
          >
            <el-radio-group v-model="form.haveAnnex">
              <el-radio :label="true">
                是
              </el-radio>
              <el-radio :label="false">
                否
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="是否必答"
            prop="isMustAnswer"
            :rules="[ { required: true, message: '是否必答不能为空', trigger: 'change' }]"
          >
            <el-radio-group v-model="form.isMustAnswer">
              <el-radio :label="true">
                是
              </el-radio>
              <el-radio :label="false">
                否
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="答题方式"
            prop="answerWay"
            :rules="[ { required: true, message: '答题方式不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.answerWay"
              class="mr10"
              placeholder="请选择答题方式"
              @change="initBelongingArea"
            >
              <el-option
                v-for="(item,index) in answerTypeOption"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col
          v-if="form.answerWay === 1 || form.answerWay === 2"
          md="6"
        >
          <el-form-item
            v-for="(item,index) in form.optionList"
            :key="index"
            :label="'选项值' + (index+1)"
            class="value-form-item"
            :prop="'optionList.' + index + '.value'"
            :rules="[ { required: true, message: '选项值不能为空', trigger: 'change' }]"
          >
            <el-input

              v-model="item.value"
            >
              <template slot="append">
                <span
                  v-if="index === 0"
                  @click="form.optionList.push({value: ''})"
                >
                  <i class="el-icon-circle-plus-outline cursor-pointer" />
                </span>
                <span
                  v-else
                  @click="form.optionList.splice(index,1)"
                >
                  <i class="el-icon-remove-outline cursor-pointer" />
                </span>
              </template>
            </el-input>
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div
      class="dialog-footer text-center"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import {
  GetQuestionCategoryTree,
  GetQuestionDetail,
  saveOrUpdateQuestionItem,
} from '@/api/assess/questionTemplate'
import SelectTree from '@core/components/select-tree/SelectTree.vue'

export default {
  components: { SelectTree },
  data() {
    return {
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'name', // 显示名称
        children: 'children', // 子级字段名
      },
      answerTypeOption: [
        { label: '单选', value: 1 },
        { label: '多选', value: 2 },
        { label: '文本', value: 3 },
      ],
      dialogVisible: false,
      loading: false,
      form: {
        categoryCode: '',
        title: '',
        code: '',
        sortNo: '',
        haveAnnex: '',
        isMustAnswer: '',
        answerWay: '',
        questionnaireCode: this.$route.query.c,
        optionList: [
          { value: '' },
        ],
      },
      saveType: 1,
      categoryCodeOptions: [],
    }
  },
  methods: {
    initBelongingArea() {
      this.form.optionList = [
        { value: '' },
      ]
    },
    getQuestionCategoryTree() {
      GetQuestionCategoryTree(this.$route.query.c).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.categoryCodeOptions = resData.data
        }
      })
    },
    dialogClose() {
      this.form = {
        categoryCode: '',
        title: '',
        code: '',
        sortNo: '',
        haveAnnex: '',
        isMustAnswer: '',
        answerWay: '',
        questionnaireCode: this.$route.query.c,
        optionList: [
          { value: '' },
        ],
      }
      this.$refs.form.resetFields()
    },
    dialogOpen() {
      this.getQuestionCategoryTree()
      if (this.form.code) {
        this.getQuestionDetail()
      }
    },
    getQuestionDetail() {
      GetQuestionDetail(this.form.code).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          if (this.form.optionList) {
            const copyList = []
            this.form.optionList.forEach(l => {
              copyList.push({ value: l })
            })
            this.form.optionList = copyList
          } else {
            this.form.optionList = [{ value: '' }]
          }
        } else {
          error(resData.msg)
        }
      })
    },
    handleSubmit(submitData) {
      this.loading = true
      saveOrUpdateQuestionItem(submitData, this.saveType).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.$emit('handleFilter')
          this.dialogVisible = false
        } else {
          error(resData.msg)
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const submitData = { ...this.form }
          submitData.sortNo = submitData.sortNo ? submitData.sortNo : 0
          submitData.optionList = submitData.optionList.map(obj => obj.value)
          // 判断选项值是否有重复项
          if (this.form.answerWay === 1 || this.form.answerWay === 2) {
            const set = new Set(submitData.optionList)
            if (set.size !== submitData.optionList.length) {
              error('选项值有重复')
            } else {
              this.handleSubmit(submitData)
            }
          } else {
            this.handleSubmit(submitData)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .el-form-more {
    display: flex;
    justify-content: flex-start;
    .el-form-item {
      flex: 1;
    }
    .el-input--suffix {
      width: 80px;
    }
    .el-input--suffix .el-input__inner {
      padding-right: 0px;
    }
  }
  .no-label .el-form-item__content {
    margin-left: 10px !important;
  }
  .el-range-editor.is-active,
  .el-range-editor.is-active:hover,
  .el-select .el-input.is-focus .el-input__inner {
    /*border-color: transparent !important;*/
  }
  .value-form-item .el-input__inner{
    width: 516px !important;
  }
</style>
