<template>
  <div class="pia-content">
    <b-card>
      <div v-show="detailShow">
        <el-row>
          <el-col
            :span="6"
            class="font-medium-1 mt-50"
          >
            <span class="title">模板名称：{{ questionnaireDetail.name }}</span>
          </el-col>
          <el-col
            :span="8"
            class="text-right font-medium-1 mt-50"
          >
            模板类型：{{ questionnaireDetail.assessCategoryName }}
          </el-col>
          <el-col
            :span="10"
            class="text-right"
          >
            <el-button
              size="small"
              @click="$router.push({ path: '/questionTemplate'})"
            >
              返 回
            </el-button>
          </el-col>
        </el-row>
      </div>
    </b-card>
    <b-row class="match-height">
      <!--评估提纲-->
      <b-col md="3">
        <b-card>
          <div class="filter-wrap flex-between">
            <h4>题目类型</h4>
            <el-button
              v-if="!r"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="assessTypeDialogShow(1,null)"
            >
              新 增
            </el-button>
          </div>
          <div :style="{maxHeight: pageH -250 + 'px',overflowY: 'scroll'}">
            <el-tree
              ref="elTree"
              :highlight-current="true"
              :data="treeData"
              :default-expanded-keys="expandKeys"
              node-key="id"
              :expand-on-click-node="false"
              :props="defaultProps"
              @current-change="treeNodeClick"
            >
              <span
                slot-scope="{ node, data }"
                class="field-tree-node"
              >
                <span>{{ node.label }} ({{data.id}})</span>
                <span
                  v-if="!r"
                  class="tree-operate"
                >
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click.stop="assessTypeDialogShow(2,data)"
                  >
                    <el-tooltip
                      content="新增"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-circle-plus-outline" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click.stop="assessTypeDialogShow(3,data)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click.stop="delNode(data,node)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </span>
              </span>
            </el-tree>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-loading="qLoading"
        xl="9"
        md="9"
      >
        <b-card>
          <div class="flex-between mb20">
            <span class="title">题目</span>
            <div
              v-if="!r"
              class="flex-end"
            >
              <el-button
                size="small"
                type="primary"
                @click="updateFinshStatus"
              >
                完  成
              </el-button>
              <el-button
                size="small"
                type="primary"
                @click="questionItemSaveDialogShow(1)"
              >
                新增题目
              </el-button>
            </div>
          </div>
          <div
            class="question-wrap"
            :style="{maxHeight: pageH - 250 + 'px'}"
          >
            <div v-if="questionList.length > 0">
              <b-row
                v-for="(detailItem,index) in questionList"
                :key="index"
                class="mb10 question-row"
                style="border-bottom: 1px solid #ddd;padding-bottom: 10px"
              >
                <b-col cols="12">
                  <div class="flex-between">
                    <h6><span
                        :style="detailItem.isMustAnswer ? {opacity: 1} : {opacity: 0}"
                        style="color:#ec151b;vertical-align: middle;"
                    >* </span>
                      {{ detailItem.code }}、 {{ detailItem.title }}
                      <el-tag v-if="detailItem.haveChild" size="medium">有子题</el-tag>
                      <el-tag v-if="!detailItem.isCanShow" size="medium">不显示</el-tag>
                      <i
                        class="el-icon-info ml10"
                        style="font-size: 16px;color: #0d40b2;cursor: pointer"
                        @click="drawerShow(detailItem)"
                      />
                    </h6>
                    <div
                      v-if="!r"
                      class="flex-end"
                    >
                      <el-button
                        class="mr10"
                        size="mini"
                        type="text"
                        @click="questionInfoDialogShow(detailItem)"
                      >
                        题目信息
                      </el-button>
                      <el-button
                        class="mr10"
                        size="mini"
                        type="text"
                        @click="linkQuestionDialogShow(detailItem)"
                      >
                        关联子题
                      </el-button>
                      <el-button
                        class="mr10"
                        size="mini"
                        type="text"
                        @click="questionItemSaveDialogShow(2, detailItem)"
                      >
                        修 改
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="delQuestion(detailItem)"
                      >
                        删 除
                      </el-button>
                    </div>
                  </div>
                  <div class="ml-2">
                    <div v-if="detailItem.answerWay === 1">
                      <el-radio
                        v-for="(item, indexOpt) in detailItem.optionList"
                        :key="indexOpt"
                        :label="item"
                      />
                    </div>
                    <div v-if="detailItem.answerWay === 2">
                      <el-checkbox
                        v-for="(item,indexOpt) in detailItem.optionList"
                        :key="indexOpt"
                        :label="item"
                        :disabled="true"
                      />
                    </div>
                    <div
                      v-if="detailItem.answerWay === 3"
                      class="mb10"
                    >
                      <el-input type="textarea" />
                    </div>
                  </div>
                  <div
                    v-if="detailItem.haveAnnex"
                    class="ml-2 flex-start"
                  >
                    附件：<i
                      class="el-icon-folder-opened"
                      style="font-size: 18px;color: #0d40b2"
                    /></div>
                </b-col>
              </b-row>
            </div>
            <el-empty v-else />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <QuestionItemSaveDialog
      ref="questionItemSaveDialog"
      @handleFilter="getQuestion"
    />
    <LabelSave ref="labelSave" />
    <QuestionInfoSave
      ref="questionInfoSave"
      @handleFilter="getQuestion"
    />
    <QuestionInfoDrawer ref="questionInfoDrawer" />
    <LinkQuestionSave
      ref="linkQuestionSave"
      @handleFilter="getQuestion"
    />
    <QusetionTypeSave
      ref="qusetionTypeSave"
      @handleFilter="getQuestionCategoryTree"
    />
  </div>
</template>

<script>
import { error, success } from '@core/utils/utils'
import {
  GetQuestionCategoryTree,
  DeleteQuestionCategory,
  GetQuestionByCategory,
  DeleteQuestion,
  UpdateFinishStatus, GetQuestionDetail, GetQuestionnaireDetail,
} from '@/api/assess/questionTemplate'
import QuestionItemSaveDialog from '@/views/assess/questionTemplate/QuestionItemSaveDialog.vue'
import LinkQuestionSave from '@/views/assess/questionTemplate/LinkQuestion.vue'
import LabelSave from './LabelSave.vue'
import QuestionInfoSave from './QuestionInfoSave.vue'
import QuestionInfoDrawer from './QuestionInfoDrawer.vue'
import QusetionTypeSave from './QusetionTypeSave.vue'

export default {
  components: {
    QuestionItemSaveDialog, LabelSave, QuestionInfoSave, QuestionInfoDrawer, QusetionTypeSave, LinkQuestionSave,
  },
  data() {
    return {
      questionList: [],
      questionnaireCode: '', // code
      questionnaireDetail: {
        code: '',
        name: '',
        subtitle: '',
        remarks: '',
        status: 0,
        assessCategoryCode: '',
        assessCategoryName: '',
      },
      r: Number(this.$route.query.r), // 是否预览
      dialogVisible: false,
      activeIndex: 0,
      expandedKeys: [],
      detailShow: true,
      treeLoading: false,
      qLoading: false,
      activeName: '0',
      loading: false,
      formLoading: false,
      expandKeys: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      questionTypeId: '',
      currentQuestionType: {},
      questionShow: false,
      currentNode: {},
    }
  },
  created() {
    this.questionnaireCode = this.$route.query.c
    this.getQuestionnaireDetail()
  },
  mounted() {
    this.navActiveInit(1, '问卷管理', '评估管理')
  },
  destroyed() {
    this.navActiveInit(2, '问卷管理', '评估管理', '/questionTemplate')
  },
  methods: {
    getQuestionnaireDetail() {
      GetQuestionnaireDetail(this.questionnaireCode).then(res => {
        this.questionnaireDetail = res.data.data
        this.getQuestionCategoryTree()
      })
    },
    linkQuestionDialogShow(item) {
      this.$refs.linkQuestionSave.dialogVisible = true
      this.$refs.linkQuestionSave.title = item.title
      this.$refs.linkQuestionSave.categoryCode = this.currentNode.id
      this.$refs.linkQuestionSave.form.code = item.id
      this.$refs.linkQuestionSave.questionCode = item.code
      this.$refs.linkQuestionSave.answerOptions = item.optionList
    },
    drawerShow(item) {
      this.$refs.questionInfoDrawer.drawerVisible = true
      this.$refs.questionInfoDrawer.form.questionId = item.id
      this.$refs.questionInfoDrawer.title = item.title
    },
    delQuestion(item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteQuestion(item.id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(res.data.msg)
            this.getQuestion()
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    delNode(data) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteQuestionCategory(this.questionnaireCode, data.id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.getQuestionCategoryTree()
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    assessTypeDialogShow(type, data) {
      this.$refs.qusetionTypeSave.dialogVisible = true
      this.$refs.qusetionTypeSave.saveType = type
      // 新增一级类型
      if (type === 1) {
        this.$refs.qusetionTypeSave.form.parentCode = ''
      }
      // 新增子类
      if (type === 2) {
        this.$refs.qusetionTypeSave.form.parentCode = data.id
        this.$refs.qusetionTypeSave.form.code = ''
        this.currentNode = data
      }
      // 修改
      if (type === 3) {
        this.currentNode = data
        this.$refs.qusetionTypeSave.form.code = data.id
      }
    },
    treeNodeClick(data) {
      this.currentNode = data
      this.getQuestion()
    },
    // 获取左边类型数
    getQuestionCategoryTree() {
      GetQuestionCategoryTree(this.questionnaireCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.expandKeys = []
          this.treeData = resData.data
          if (this.treeData.length > 0) {
            this.expandKeys.push(this.treeData[0].id)
            this.currentNode = this.treeData[0]
            this.getQuestion()
            this.$nextTick(() => {
              this.$refs.elTree.setCurrentKey(this.treeData[0].id)
            })
          }
        }
      })
    },
    // 获取详情
    getQuestion() {
      GetQuestionByCategory(this.$route.query.c, this.currentNode.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.questionList = resData.data
        }
      })
    },
    updateFinshStatus() {
      this.$confirm('确认完成吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const submitData = {
          code: this.$route.query.c,
          status: 1,
        }
        UpdateFinishStatus(submitData).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.back()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    questionInfoDialogShow(item) {
      this.$refs.questionInfoSave.dialogVisible = true
      console.log(item)
      this.$refs.questionInfoSave.form.questionId = item.id
    },
    questionItemSaveDialogShow(type, item) {
      this.$refs.questionItemSaveDialog.dialogVisible = true
      this.$refs.questionItemSaveDialog.saveType = type
      this.$refs.questionItemSaveDialog.form.categoryCode = this.currentNode.id
      if (type === 2) {
        this.$refs.questionItemSaveDialog.form.code = item.id
      } else {
        this.$refs.questionItemSaveDialog.form.sortNo = this.questionList.length + 1
      }
    },
    submitForm() {},
    back() {
      this.$router.push({ path: '/questionTemplate' })
    },
  },
}
</script>

<style>
    .question-wrap .el-checkbox__input.is-disabled+span.el-checkbox__label {
      cursor: default !important;
      color: #606266 !important;
    }
    .tree-wrap,
    .question-content-wrap {
        border: 1px solid #dcdfe6;
        padding: 10px;
        border-radius: 4px;
        height: 100%;
        /*overflow:scroll;*/
    }
     .el-checkbox__input.is-disabled .el-checkbox__inner {
      background-color: #ffffff !important;
      border-color: #DCDFE6;
       cursor: default !important;
    }
    .pia-content {
        height: 100%;
    }
    .gist-item {
        padding: 5px;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
        cursor: pointer;
        font-size: 12px;
    }
    .gist-item.is-active {
        background: #0d40b2;
        color: #ffffff;
    }

    .answer-active {
        background: #0d40b2;
        color: #ffffff !important;
        /*transform: scale(1.2);*/
    }

    .answer-reject,
    .answer-reject .check-box,
    .answer-reject i {
        border-color: #f56c6c !important;
        color: #f56c6c !important;
    }
    .answer-disable {
        cursor: not-allowed;
        pointer-events: none;
        color: #7f7f7f;
    }

    .completed-btn {
        position: absolute;
        top: 10px;
        right: 24px;
    }

    /*tree */
    [dir="ltr"] .el-badge__content.is-fixed {
        right: 0;
        top: 3px;
    }
    .title {
        border-left: solid 6px #0d40b2;
        padding-left: 8px;
        margin-bottom: 2px;
    }
    .pia-content .el-checkbox__label {
        vertical-align: middle;
        white-space: normal;
    }
    .custom-name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .question-wrap {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .pop-content {
        max-height: 500px;
        white-space: pre-wrap;
        overflow: auto;
    }
    .tree-operate{
      display: none;
      position: absolute;
      right: 0;
    }
    .field-tree-node{
      width: 100%;
      position: relative;
    }
    .field-tree-node:hover{
      .tree-operate{
        display: inline-block;
      }
    }
    .question-row .el-radio,
    .question-row .el-checkbox{
      display: block;
    }
    .question-row .el-radio__label,
    .question-row .el-checkbox__label{
      //max-width: 80%;
      white-space: break-spaces;
    }
</style>
