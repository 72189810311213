<template>
  <el-dialog
    :title="titleObj[saveType]"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    >
      <el-form-item
        label="类型名称"
        prop="name"
        :rules="[ { required: true, message: '问卷名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        label="类型编号"
        prop="code"
        :rules="[ { required: true, message: '问卷code不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.code"
          :disabled="saveType === 3"
        />
      </el-form-item>
      <el-form-item label="排序号">
        <el-input
          v-model.number="form.sortNo"
          type="number"
        />
      </el-form-item>
      <el-form-item label="说明">
        <el-input
          v-model="form.explanation"
          type="textarea"
          :autosize="autosize"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { getQuestionTypeInfo, saveOrUpdateQuestionType } from '@/api/assess/questionTemplate'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      titleObj: {
        1: '新增一级类型',
        2: '新增子类',
        3: '修改类型',
      },
      form: {
        parentCode: '',
        name: '',
        code: '',
        questionnaireCode: this.$route.query.c,
        explanation: '',
        sortNo: '',
      },
      saveType: 1,
      groupOptions: [],
      dimensions: [],
    }
  },
  methods: {
    getDetail() {
      getQuestionTypeInfo(this.form.questionnaireCode,this.form.code).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
    dialogOpen() {
      if (this.form.code) {
        this.title = '修改题目类型'
      } else {
        this.title = '新增题目类型'
      }
      if(this.form.code){
        this.getDetail()
      }
    },
    dialogClose() {
      this.form = {
        name: '',
        code: '',
        questionnaireCode: this.$route.query.c,
        explanation: '',
        sortNo: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.sortNo = this.form.sortNo ? this.form.sortNo : 0
          saveOrUpdateQuestionType(this.form, this.saveType).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter')
              this.dialogVisible = false
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
