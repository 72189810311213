<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    >
      <el-form-item label="说明">
        <el-input
          v-model="form.explanation"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="注意事项">
        <el-input
          v-model="form.precautions"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="相关术语">
        <el-input
          v-model="form.relatedTerm"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
        v-for="(item,index) in form.lawList"
        :key="index"
        class="form-item-flex"
        label="关联法律"
      >
        <el-select
          v-model="item.lawCode"
          class="mr10"
          placeholder="请选择法律"
          @change="getDetailsOptions(item)"
        >
          <el-option
            v-for="(law,k) in lawsOption"
            :key="k"
            :label="law.LawName"
            :value="law.lawCode"
          />
        </el-select>
        <el-select
          v-model="item.lawDetailsCode"
          placeholder="请选择章节"
        >
          <el-option
            v-for="(lawDetail,k) in item.chapterOption"
            :key="k"
            :label="lawDetail.labelName"
            :value="lawDetail.lawDetailsId"
          />
        </el-select>
        <i
          v-if="index === 0"
          class="el-icon-circle-plus-outline cursor-pointer"
          @click="form.lawList.push({ lawCode: '', lawDetailsCode: '', chapterOption: [] })"
        />
        <i
          v-else
          class="el-icon-remove-outline cursor-pointer"
          @click="form.lawList.splice(index,1)"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { CreateOrUpdateAffiliatedInfo, GetAffiliateInfo } from '@/api/assess/questionTemplate'
import {
  GetDetailsOptions,
  GetLawOptions,
} from '@/api/knowledgeBase/laws'

export default {
  data() {
    return {
      lawsOption: [],
      loading: false,
      dialogVisible: false,
      title: '题目信息',
      form: {
        explanation: '',
        precautions: '',
        questionId: '',
        relatedKnowledge: '',
        relatedTerm: '',
        legalBasis: '',
        lawList: [
          { lawCode: '', lawDetailsCode: '', chapterOption: [] },
        ],
      },
      chapterOption: [],
    }
  },
  methods: {
    dialogOpen() {
      this.getLawOptions()
      this.getDetail()
    },
    getLawOptions() {
      GetLawOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawsOption = resData.data
        }
      })
    },
    getLawUnscrambleOptions(item) {
      GetDetailsOptions({ lawCode: item.lawCode }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          item.chapterOption = resData.data
        }
      })
    },
    getDetailsOptions(item) {
      item.lawDetailsCode = ''
      this.getLawUnscrambleOptions(item)
    },
    getDetail() {
      GetAffiliateInfo(this.form.questionId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          this.form.lawList = this.form.lawList ? this.form.lawList : [{ lawCode: '', lawDetailsCode: '', chapterOption: [] }]
          if(this.form.lawList.length > 0){
            this.form.lawList.forEach(item=>{
              this.$set(item,'chapterOption', [])
              // item.chapterOption = []
              this.getLawUnscrambleOptions(item)
            })
          }
        }
      })
    },
    dialogClose() {
      this.form = {
        explanation: '',
        precautions: '',
        questionId: '',
        relatedKnowledge: '',
        relatedTerm: '',
        legalBasis: '',
        lawList: [
          { lawCode: '', lawDetailsCode: '', chapterOption: [] },
        ],
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          CreateOrUpdateAffiliatedInfo(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter')
              this.dialogVisible = false
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.el-icon-circle-plus-outline,  .el-icon-remove-outline{
  position: absolute;
  right: 20px;
  font-size: 20px;
  top: 10px;
}
</style>
